<template>
  <!-- begin:: Header Topbar -->
  <div class="kt-header__topbar">
    <!--begin: User Bar -->
    <div class="kt-header__topbar-item kt-header__topbar-item--user">
      <div class="kt-header__topbar-wrapper" id="kt_user_toggle">
        <div class="kt-header__topbar-user">
          <span class="kt-header__topbar-welcome kt-hidden-mobile"
            >{{ $t("DATA.HI") }},</span
          >
          <span class="kt-header__topbar-username kt-hidden-mobile">{{
            userName
          }}</span>
          <div class="mr-2" v-if="timeToChangePassword">
            <router-link to="/profile" class="blinking-wrapper">
              <svg height="20" width="20" class="blinking">
                <circle cx="10" cy="10" r="10" fill="red" />
                Sorry, your browser does not support inline SVG.
              </svg>
              {{ $t("DATA.CHANGE_YOUR_PASSWORD") }}
            </router-link>
          </div>
          <!--use below badge element instead the user avatar to display username's first letter(remove kt-hidden class to display it) -->
          <!-- <span
            class="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold"
            >{{ userLetter }}</span
          > -->
          <router-link
            to="/profile"
            class="btn btn-label btn-label-brand btn-sm btn-bold mr-2"
            >{{ $t("DATA.MY_PROFILE") }}</router-link
          >
          <a
            href="#"
            v-on:click="onLogout()"
            class="btn btn-label btn-label-brand btn-sm btn-bold"
            >{{ $t("DATA.EXIT") }}</a
          >
        </div>
      </div>
      <div
        class="dropdown-menu dropdown-menu-fit dropdown-menu-xl dropdown-menu-right"
        v-on:click.stop
      >
        <KTDropdownUser :name="userName" :letter="userLetter"> </KTDropdownUser>
      </div>
    </div>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<script>
import KTDropdownUser from "@/views/theme/topbar/DropdownUser.vue";
import i18nService from "@/common/i18n.service.js";
import { mapGetters } from "vuex";

import { LOGOUT } from "@/store/auth.module";
import { GET_TIME_TO_CHANGE_PASSWORD } from "@/store/profile.module";

export default {
  name: "KTTopbar",
  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages
    };
  },
  components: {
    KTDropdownUser
  },
  mounted() {
    this.fetchTimeForChangePassword();
  },
  methods: {
    onLanguageChanged() {
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    },
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    fetchTimeForChangePassword() {
      this.$store.dispatch(GET_TIME_TO_CHANGE_PASSWORD);
    }
  },
  computed: {
    ...mapGetters(["currentUser", "timeToChangePassword"]),

    getLanguageFlag() {
      return this.onLanguageChanged();
    },
    userName() {
      return this.currentUser.name;
    },
    userLetter() {
      return this.currentUser.name.charAt(0);
    }
  }
};
</script>
<style>
.blinking {
  -webkit-animation: 1s blink ease infinite;
  -moz-animation: 1s blink ease infinite;
  -ms-animation: 1s blink ease infinite;
  -o-animation: 1s blink ease infinite;
  animation: 1s blink ease infinite;
}

@keyframes "blink" {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-moz-keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes "blink" {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-ms-keyframes "blink" {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-o-keyframes "blink" {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.blinking-wrapper {
  color: inherit;
  text-decoration: none;
}
</style>
